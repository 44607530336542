<script setup>
import { useStore } from 'vuex'

const store = useStore()
store.commit('initializeStore')
</script>

<template>
  <div>
    <router-view ></router-view>
  </div>
</template>