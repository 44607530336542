import { createStore } from 'vuex'

export default createStore({
	state: {
		token: '',
		isAuthenticated: false,
	},
	getters: {
		token(state) {
			return state.token
		},
		isAuthenticated(state) {
			return state.isAuthenticated
		},
	},
	mutations: {
		initializeStore(state) {
			if (localStorage.getItem('token')) {
				state.token = localStorage.getItem('token')
				state.isAuthenticated = true
			} else {
				state.token = ''
				state.isAuthenticated = false
			}
		},
		setToken(state, param) {
			state.token = param.token
			state.isAuthenticated = true
			if (param.remember_login) {
				localStorage.setItem('token', param.token)
			}
		},
		removeToken(state) {
			state.token = ''
			state.isAuthenticated = false
			if (localStorage.getItem('token')) {
				localStorage.removeItem('token')
			}
		}
	},
	actions: {
	},
	modules: {
	}
})
